import {
  algoliaEventAddToCartAfterSearch,
  algoliaEventAddToCart
} from "~/utils/algolia"
import { FREQUENTLY_BOUGHT_TOGETHER } from "~/config/algolia-insights"
import { algoliaSearch as algoliaSearchHelper } from "~/config/algolia"
import { useSessionStorage } from "@vueuse/core"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = await useNuxtApp()
  if (!from?.name || from.name === "checkout") return

  const { $cl } = useNuxtApp()

  const alreadyOpened = useSessionStorage("already-opened-precheckout", false)

  const {
    preCheckoutProducts,
    initPrecheckoutProducts,
    fetchPreCheckoutProducts
  } = usePreCheckout()
  const { openModal, statusModal, closeModal } = useModal("pre-checkout")
  const { cart } = useCart()
  const algolia = await algoliaSearchHelper()
  const algoliaSearch = useAlgoliaSearch(`TOP_PRODUCTS${checkEnvSite}`)

  if (cart?.value?.line_items && cart?.value?.line_items?.length > 1) {
    if (algoliaSearch.result.value?.queryID) {
      algoliaEventAddToCartAfterSearch(
        algolia.indices.products,
        `${FREQUENTLY_BOUGHT_TOGETHER}`,
        algoliaSearch.result.value.queryID,
        cart?.value?.line_items?.map(
          (lineItem) => `product_${lineItem.sku_code}`
        ) ?? []
      )
    } else {
      algoliaEventAddToCart(
        algolia.indices.products,
        `${FREQUENTLY_BOUGHT_TOGETHER}`,
        cart?.value?.line_items?.map(
          (lineItem) => `product_${lineItem.sku_code}`
        ) ?? []
      )
    }
  }

  await fetchPreCheckoutProducts() // As per TF-2387, we fix the "Ti server altro?" modal by fetching the pre-checkout products before checking if they are in stock
  initPrecheckoutProducts()

  let hasPreCheckoutProductsInStock

  try {
    const commerceLayerProducts = await $cl.skus.list({
      filters: {
        code_in: `"${preCheckoutProducts.value?.join(",")}"`,
        stock_items_quantity_gt: 0
      }
    })

    hasPreCheckoutProductsInStock = !!commerceLayerProducts.length
  } catch (error) {
    hasPreCheckoutProductsInStock = true
  }

  if (
    !!preCheckoutProducts.value?.length &&
    !statusModal.value &&
    hasPreCheckoutProductsInStock
  ) {
    if (!alreadyOpened.value) {
      openModal("pre-checkout")
      alreadyOpened.value = true
      return nuxtApp.runWithContext(() => abortNavigation())
    }
  }

  closeModal("pre-checkout")
})
